
import { defineComponent } from 'vue';
import {mapGetters,mapActions} from "vuex";
import apiService from "@/api/api";
// import (mapGetters())

export default defineComponent({
  name: 'AdminPanel',
  props: {
    userEmail: {type: String, default: ''},
    disableFooter: {type: Boolean, default: false},
    disableHeader: {type: Boolean, default: false},
    refresh: {type: Boolean, required: true}
  },
  data() {
    const menu: any = {}
    return {
      logoutClicked: false,
      menuOpened: true,
      catgories: {},
      keys: [],
      currentMenuItem: null,
      currentChosenTable: {},
      sites: [],
      siteSelectHeight: 0,
      clickedPageName: '',
      menu,
      permissions: {}
    }
  },
  computed: {
    // смешиваем результат mapGetters с внешним объектом computed
    ...mapGetters([
      'AUTHORIZED',
      'MENU'
      // ...
    ])
  },
  beforeMount() {
    this.$store.dispatch('initCompleted').then((inited: boolean) => {
      console.log('admin panel on imit: ', inited, this.AUTHORIZED, this.MENU)
      if (inited && this.AUTHORIZED && this.MENU != null) {
        const menu = this.MENU
        const keys = Object.keys(menu)
        const newMenu: any = {}
        for (let i = 0; i < keys.length; i++) {
          ((index) => {
            // console.log('RTW: ', this.hasRightsToWatch(+keys[index]))
            if (this.hasRightsToWatch(+keys[index])) {
              newMenu[+keys[index]] = {title: menu[+keys[index]].title, submenu: {}}
              const subKeys = Object.keys(menu[+keys[index]].submenu)
              if (subKeys.length === 0 && index === keys.length - 1) {
                this.setNewMenu(newMenu)
                return
              }
              for (let j = 0; j < subKeys.length; j++) {
                // console.log(j, this.hasRightsToWatch(+keys[index], +subKeys[j]))
                if (this.hasRightsToWatch(+keys[index], +subKeys[j])) {
                  newMenu[+keys[index]].submenu[+subKeys[j]] = {key: menu[+keys[index]].submenu[+subKeys[j]].key, title: menu[+keys[index]].submenu[+subKeys[j]].title}
                }
                if (j === subKeys.length - 1 && index === keys.length - 1) {
                  this.setNewMenu(newMenu)
                }
              }
            } else {
              if (index === keys.length - 1) {
                this.setNewMenu(newMenu)
              }
            }
          })(i)
        }
      }
    })
  },
  methods: {
    ...mapActions([
       'notify',
       'userLoggedOut'
    ]),
    setNewMenu(newMenu: any) {
      this.menu = newMenu
      this.$forceUpdate()
      this.$nextTick().then(() => {
        if (localStorage.tab != null && localStorage.m_key != null && localStorage.m_sub != null) {
          if (this.menuContainsValues(localStorage.m_key, localStorage.m_sub, localStorage.tab)) {
            this.menuClick(localStorage.tab, +localStorage.m_key, +localStorage.m_sub)
            return
          }
        }
        const clickKey = Object.keys(this.menu)[0]
        const clickSubKey = Object.keys(this.menu[+clickKey].submenu)[0]
        this.menuClick(this.menu[+clickKey].submenu[+clickSubKey].key, +clickKey, +clickSubKey)
      })
      // console.log('NEW MENU: ', JSON.stringify(this.menu))
    },
    menuContainsValues(key: number, subKey: number, tab: string) {
      return this.menu[key] != null && (this.menu[key].submenu != null && this.menu[key].submenu[subKey] != null && this.menu[key].submenu[subKey].key === tab)
    },

    bodyClick(e: any) {
      if (this.logoutClicked && (e && e.target && e.target.getAttribute('data-click') == null)) this.logoutClicked = false
    },
    menuItemActive(page: string): boolean {
      return this.clickedPageName === page
    },
    hasRightsToWatch(key: number, subkey?: number): boolean {
      return true;

      // if (this.permissions.superAdmin === true) return true;
      // if (!subkey) {
      //   if (this.permissions[key]) {
      //     const keys = Object.keys(this.permissions[key]);
      //     if (!keys.length) return false;
      //     for (let i = 0; i < keys.length; i++) {
      //       if (this.permissions[key][keys[i]] > 0) return true;
      //       if (i === keys.length - 1) return false;
      //     }
      //   } else {
      //     return false;
      //   }
      // } else {
      //   if (this.permissions[key] && this.permissions[key][subkey]) {
      //     return this.permissions[key][subkey] > 0;
      //   } else {
      //     return false;
      //   }
      // }
    },
    handleMenu() {
      this.menuOpened = !this.menuOpened;
      const divs = document.querySelectorAll('.slide');
      if (divs && divs.length) {
        for (let i = 0; i < divs.length; i++) {
          if (this.menuOpened) divs[i].setAttribute('style', 'min-width: 50px; max-width: 50px;');
          else divs[i].removeAttribute('style');
        }
      }
    },
    menuClick(name: string, key: number, subkey: number) {
      // console.log('MENU CLICK: ', name, key, subkey)
      this.clickedPageName = name
      localStorage.tab = this.clickedPageName
      if ((key && !isNaN(key)) && (subkey && !isNaN(subkey))) {
        localStorage.m_key = key
        localStorage.m_sub = subkey
      }
      this.$emit('menu_click', name)
    },
    logOut() {
      apiService.logOut().then((r: any): any => {
        console.log('logout resp: ', r.data)
        if (!r.data.error) {
          this.userLoggedOut()
          this.$router.replace('/')
          this.notify({type: 's', text: 'Сессия удалена'})
        } else {
          console.error(r.data.error)
          this.notify({type: 'd', text: 'Ошибка'})
        }
      }).catch((e: any) => {
        console.error(e)
        this.notify({type: 'd', text: 'Ошибка'})
      })
    }
  }
});
