import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e4e5b18"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "table"
}
const _hoisted_2 = { class: "thead" }
const _hoisted_3 = { class: "tr head" }
const _hoisted_4 = { class: "tbody" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.tableData.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataKeys(), (key, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["th", {'images': key === 'Images' || key === 'Image', 'data-id': key === 'Id'}]),
                key: 'th-' + i
              }, _toDisplayString(key), 3))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (d, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["tr", {'selected': i === _ctx.selectedIndex}]),
              onClick: ($event: any) => (_ctx.selectRow(i, d)),
              key: 'tr-' + i
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keysNumber(i), (num) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["td", {'images': _ctx.keyIs('images', i, num - 1) || _ctx.keyIs('image', i, num - 1), 'data-id': _ctx.dataKeys()[num - 1] === 'Id'}]),
                  key: 'td-' + (num - 1)
                }, [
                  (_ctx.keyIs('images', i, num - 1) || _ctx.keyIs('image', i, num - 1))
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.imageArray(d.images != null ? d.images : d.image), (img, i) => {
                        return (_openBlock(), _createElementBlock("img", {
                          src: _ctx.imgSrc(img),
                          alt: "",
                          key: 'tbl-img-' + i
                        }, null, 8, _hoisted_6))
                      }), 128))
                    : (_ctx.keyIs('status', i, num - 1))
                      ? (_openBlock(), _createElementBlock("b", _hoisted_7, _toDisplayString(_ctx.statusString(_ctx.keyValue(i, num - 1))), 1))
                      : (_openBlock(), _createElementBlock("b", _hoisted_8, _toDisplayString(_ctx.keyValue(i, num - 1)), 1))
                ], 2))
              }), 128))
            ], 10, _hoisted_5))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}