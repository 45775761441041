import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")!
  const _component_AdminPanel = _resolveComponent("AdminPanel")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AdminPanel, {
      refresh: true,
      onMenu_click: _ctx.onMenuClick,
      onNotify: _ctx.notify
    }, {
      "th-s": _withCtx(() => [
        _createElementVNode("button", {
          class: "big",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onButtonClick('add')))
        }, "Добавить " + _toDisplayString(_ctx.buttonText), 1)
      ]),
      "th-e": _withCtx(() => [
        (_ctx.buttonsActivated)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onButtonClick('edit')))
              }, "Редактировать " + _toDisplayString(_ctx.buttonText), 1),
              _createElementVNode("button", {
                class: "del",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onButtonClick('del')))
              }, "Удалить " + _toDisplayString(_ctx.buttonText), 1)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      tb: _withCtx(() => [
        _createVNode(_component_Table, {
          "table-data": _ctx.tableData,
          "tab-key": _ctx.getTabKey(),
          onRowSelect: _ctx.onTableRowSelect
        }, null, 8, ["table-data", "tab-key", "onRowSelect"])
      ]),
      _: 1
    }, 8, ["onMenu_click", "onNotify"]),
    _createVNode(_component_Form, {
      show: _ctx.showForm,
      tab: _ctx.currentTab,
      "tab-key": _ctx.getTabKey(),
      action: _ctx.formAction,
      data: _ctx.formData,
      onClose: _ctx.closeForm,
      onSubmit: _ctx.submitForm,
      onNotify: _ctx.notify
    }, null, 8, ["show", "tab", "tab-key", "action", "data", "onClose", "onSubmit", "onNotify"])
  ]))
}