
import {defineComponent} from 'vue';
import {mapGetters,mapActions} from "vuex";
import AdminPanel from "../components/AdminPanel.vue";
import Table from "@/components/Table.vue";
import Form from "@/components/Form.vue";
import apiService from "@/api/api";
import PRODUCT, {newProduct} from "@/interfaces/product";
import CATEGORY, {newCategorie} from "@/interfaces/category";

export default defineComponent({
  name: 'Console',
  components: {
    AdminPanel, Table, Form
  },
  data() {
    let formData: any = {}
    let tableData: any[] = []
    return {
      showForm: false,
      currentTab: '',
      formAction: '',
      formData,
      tableData
    }
  },
  computed: {
    ...mapGetters([
      'PRODUCTS',
      'CATEGORIES',
      'USERS'
      // ...
    ]),
    buttonText(): string {
      switch (this.currentTab) {
        default:
         return ''
        case 'categories':
          return 'категорию'
        case 'products':
          return 'товар'
        case 'users':
          return 'пользователя'
      }
    },
    buttonsActivated(): boolean {
      return Object.keys(this.formData).length > 0
    }
  },
  watch: {
    currentTab: function () {
      // console.log('Current tab watch: ', this.currentTab)
      this.formData = {}
      this.refreshTableData()
    },
  },
  methods: {
    ...mapActions([
       'notify'
    ]),
    refreshTableData() {
      this.tableData = []
      switch (this.currentTab) {
        default:
          break
        case 'categories':
          apiService.get('c').then((r: any): any => {
            console.log('c response: ', r)
            if (r.data.error) return
            if (r.data.categories.length) {
              for (let i = 0; i < r.data.categories.length; i++) {
                this.tableData.push(newCategorie(r.data.categories[i]))
              }
            }
          })
          break
        case 'products':
          apiService.get('ps').then((r: any): any => {
            console.log('p response: ', r)
            if (r.data.error) return
            if (r.data.products.length) {
              for (let i = 0; i < r.data.products.length; i++) {
                this.tableData.push(newProduct(r.data.products[i]))
              }
            }
          })
          break
        case 'users':
          apiService.get('a').then((r: any): any => {
            console.log('u response: ', r)
            if (r.data.error) return
            this.tableData = r.data.users || []
          })
      }
    },
    currentTabIs(tab: string): boolean {
      // console.log('curr tab: |', this.currentTab, '| ', tab)
      return this.currentTab === tab
    },
    onMenuClick(event: string) {
      // console.log('on menu click: ', event)
      this.currentTab = event
    },
    onTableRowSelect(event: any) {
      // console.log('row selected: ', JSON.stringify(event))
      if (event != null) this.formData = event
      else this.formData = {}
    },
    onButtonClick(action: string) {
      // console.log('btn-clck', action)
      // if (event.action === 'edit' || event.action === 'del') this.formData = event.data
      this.formAction = action
      this.showForm = true
    },
    getTabKey(): string {
      switch (this.currentTab) {
          case 'categories':
            return 'c'
          case 'users':
            return 'u'
          case 'products':
            return 'p'
          default:
            return ''
        }
    },
    async submitForm(event: any) {
      console.log('submit form: ', event)
      if (!event || !event.action || !event.data) return
      const key = this.getTabKey()
      switch (event.action) {
        case 'add':
          apiService.insert(key, event.data, event.images != null ? event.images : event.image).then((r: any): any => {
            console.log('insert response: ', r)
            if (!r.data.error) {
              this.notify({type: 's', text: 'Успешно сохранено'})
              this.refreshTableData()
              this.closeForm()
            }
          }).catch((e: any) => {
            console.error(e)
          })
          break
        case 'edit':
          apiService.update(key, event.data, event.images != null ? event.images : event.image).then((r: any): any => {
            console.log('update response: ', r)
            if (!r.data.error) {
              this.notify({type: 's', text: 'Успешно отредактировано'})
              this.refreshTableData()
              this.closeForm()
            }
          }).catch((e: any) => {
            console.error(e)
          })
          break
        case 'del':
          apiService.remove(key, event.data.id).then((r: any): any => {
            console.log('delete response: ', r)
            if (!r.data.error) {
              this.notify({type: 's', text: 'Успешно удалено'})
              this.refreshTableData()
              this.closeForm()
            }
          }).catch((e: any) => {
            console.error(e)
          })
          break
        default:
            return
      }
    },
    closeForm() {
      this.showForm = false
      this.formAction = ''
      // this.formData = {}
    }
  }
});
