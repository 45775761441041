
import { defineComponent, PropType } from 'vue'
import apiService from "@/api/api";

export default defineComponent({
  name: "Table",
  props: {
    // title: {type: Array as PropType<string[]>, default: () => []},
    tableData: {type: Array as PropType<{ [key: string]: any }>, default: () => []},
    tabKey: {type: String, default: ''}
  },
  data() {
    return {
      selectedIndex: -1
    }
  },
  watch: {
    title: function () {
      console.log('title watch')
      this.$forceUpdate()
    },
    tableData: function () {
      this.selectedIndex = -1
      if (this.tableData.length === 0) {
        this.$emit('row-select', null)
        return
      }
      this.$forceUpdate()
    }
  },
  methods: {
    imgSrc(image: string): string {
      return apiService.getSrc(image, this.tabKey)
    },
    keysNumber(index: number): number {
      try {
        // console.log('data watch: ', index, JSON.stringify(this.tableData), this.tableData[index])
        if (this.tableData.length - 1 < index || this.tableData[index] == null) return 0
        const keys = Object.keys(this.tableData[index])
        return keys.length
      } catch (e) {
        console.error(e)
        return 0
      }
    },
    keyValue(outerIndex: number, innerIndex: number): any {
      try {
        const obj = this.tableData[outerIndex]
        if (obj == null) return 'error'
        const keys = Object.keys(obj)
        if (keys.length - 1 < innerIndex) return 'error'
        // if (keys[innerIndex] === 'price')
        return obj[keys[innerIndex]]
      } catch (e) {
        console.error(e)
        return 'error'
      }
    },
    keyIs(key: string, outerIndex: number, innerIndex?: number): boolean {
      try {
        let keys: string[]
        let obj: any = null
        if (innerIndex) {
          obj = this.tableData[outerIndex]
          if (obj == null) return false
          keys = Object.keys(obj)
          if (keys.length - 1 < innerIndex) return false
          return keys[innerIndex] === key
        } else {
          return false
        }
      } catch (e) {
        console.error(e)
        return false
      }
    },
    imageArray(images: string): string[] {
      return images.split(',')
    },
    dataKeys(): string[] {
      if (!this.tableData.length) return []
      try {
        const keys = Object.keys(this.tableData[0])
        if (!keys.length) return []
        for (let i = 0; i < keys.length; i++) {
          keys[i] = keys[i].charAt(0).toUpperCase() + keys[i].slice(1)
          if (i === keys.length - 1) return keys
        }
        return keys
      } catch {
        return []
      }
    },
    emitButtonClick(data: any, edit?: boolean) {
      this.$emit('btn-click', {data, action: edit ? 'edit' : 'del'})
    },
    selectRow(index: number, data: any) {
      if (this.selectedIndex !== index) {
        this.selectedIndex = index
        this.$emit('row-select', data)
      } else {
        this.selectedIndex = -1
        this.$emit('row-select', null)
      }
    },
    statusString(status: number): string {
      if (status <= 0) return 'Недоступен для возврата или обмена'
      else {
        switch (status) {
          case 1:
            return 'Доступен обмен'
          case 2:
            return 'Доступен возврат'
          case 3:
            return 'Доступен возврат или обмен'
          default:
            return 'Неправильный статус'
        }
      }
    }
  }
})
